<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from 'axios';

export default {
    data: function () {
		return {
            ffxivnews: []
		}
	},
	created: async function () {
		await this.fetchFFXIVNewsData()
	},
	methods: {
		fetchFFXIVNewsData: function () {
            const uri = process.env.VUE_APP_XIVAPI + '/news'
            this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
                this.ffxivnews = response.data
				this.debugLevel(['FFXIV News data successfully loaded: ', this.ffxivnews])
			}).catch((error) => {
				this.debugLevel('FFXIV News data failed to load with error: ' + error)
			})
		},
        debugLevel: function (value, level = 2) {
            const values = [];
            Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
            switch (level) {
                case 1:
                    if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                }
                break;
                case 2:
                if (process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                }
                break;
                default:
                values.forEach(element => console.log(element));
                break;
            }
        },
    },
    page: {
            title: "News",
            meta: [{ name: "description", content: appConfig.description }]
        },
    components: {
        Layout
    },
}
</script>
<template>
<Layout>
    <div class="row align-items-center">
        <div class="col-sm-6">
            <div class="page-title-box">
                <h4 class="page-title">{{ $t('main.news') }}</h4>
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">XIVDATA</li>
                    <li class="breadcrumb-item active">{{ $t('main.news') }}</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div v-for="news in ffxivnews.slice(0, 10)" :key="news.id" class="row no-gutters position-relative">
                        <div class="col-md-6 mb-md-0 p-md-4">
                            <img :src="news.image" class="w-100">
                        </div>
                        <div class="col-md-6 position-static p-4 pl-md-0">
                            <h5 class="mt-0">{{ news.title }}</h5>
                            <p>{{ news.description }}</p>
                            <a :href="news.url" target="_blank" class="stretched-link">{{ $t('news.view') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Layout>
</template>